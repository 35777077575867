import { BsChevronDown, BsPersonFill } from 'react-icons/bs';
import {
  MdOutlineEmojiPeople,
  MdExitToApp,
  MdWorkHistory,
} from 'react-icons/md';
import { FaHamburger, FaHandPointRight } from 'react-icons/fa';
import Henok from './assets/profile.png';
import { useEffect, useRef, useState } from 'react';
import { featuredProjects, otherProjects } from './utils/projects';
import Project from './components/singleProject';
import { gsap } from 'gsap';
import {
  AiFillGithub,
  AiFillLinkedin,
  AiOutlineFundProjectionScreen,
} from 'react-icons/ai';
import loyalty1 from './assets/projects/loyalty_1.png';
import loyalty2 from './assets/projects/loyalty_2.png';
import shufer1 from './assets/projects/shufer_1.png';
import shufer2 from './assets/projects/shufer_2.png';
import green1 from './assets/projects/green_1.png';
import green2 from './assets/projects/green_2.png';
import green3 from './assets/projects/green_3.png';
import medhanite1 from './assets/projects/medhanite_1.png';
import medhanite2 from './assets/projects/medhanite_2.png';
import medhanite3 from './assets/projects/medhanite_3.png';
import getz1 from './assets/projects/getz_1.png';
import getz2 from './assets/projects/getz_2.png';
import getz3 from './assets/projects/getz_3.png';
import crypto1 from './assets/projects/crypto_1.png';
import crypto2 from './assets/projects/crypto_2.png';
import crypto3 from './assets/projects/crypto_3.png';
import TypingText from './components/typingText';
import { Companies } from './utils/companies';

function App() {
  const [activeSlide, setActiveSlide] = useState(null);
  const [text, setText] = useState(0);
  const [show, setShow] = useState(false);
  const projDescHolder = useRef(null);

  const texts = [
    'Full-stack Developer.',
    'fitness enthusiast.',
    'car enthusiast.',
    'nature aficionado.',
  ];

  const handleSectionScroll = (id) => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSlideClick = (slideId) => {
    setActiveSlide(slideId === activeSlide ? null : slideId);
  };

  const pics = [
    [loyalty1, loyalty2],
    [shufer1, shufer2],
    [green1, green2, green3],
    [medhanite1, medhanite2, medhanite3],
    [getz1, getz2, getz3],
    [crypto1, crypto2, crypto3]
  ];

  useEffect(() => {
    gsap.fromTo(
      '.nav-links',
      { opacity: 0, y: '-50px' },
      { y: 0, opacity: 1, duration: 0.3, stagger: 0.4 }
    );
  }, [show]);

  useEffect(() => {
    gsap.fromTo(
      '.intro',
      { y: '50px', opacity: 0 },
      { y: 0, opacity: 1, duration: 0.5, delay: 1, stagger: 0.3 }
    );
    if (activeSlide) {
      let height = 0;
      const comp = document.querySelector(`.${activeSlide}`).children;
      for (let i = 0; i < comp.length; i++) {
        height += comp[i].offsetHeight;
      }
      gsap.to('.companies', { height: 0, padding: 0, duration: 1 });
      gsap.to('.role-list', { opacity: 0, x: '-32px', duration: 1 });
      gsap.to(`.${activeSlide}`, {
        height: `${height + 50}`,
        padding: '10px 0',
        duration: 1,
      });
      gsap.to(`.${activeSlide}-role`, {
        x: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.5,
        delay: 1,
      });
    } else {
      gsap.to('.role-list', { opacity: 0, x: '-32px', duration: 0.5 });
      gsap.to('.companies', { height: 0, padding: 0, duration: 0.5, delay: 1 });
    }
  }, [activeSlide]);

  return (
    <main className="flex min-h-screen flex-col m-auto w-5/6 lg:w-4/6 gap-6 items-center justify-between scroll-smooth">
      <header className="fixed z-20 w-full px-5">
        <nav className="relative flex flex-col lg:flex-row items-center gap-[50px] px-10 py-3 mx-auto justify-end bg-gradient-to-b from-gray-900 backdrop:blur-md">
          <span
            onClick={() => handleSectionScroll('home')}
            className="absolute left-3 top-3 text-lg font-pro cursor-pointer"
          >
            Henok Tesfu
          </span>
          {!show ? (
            <FaHamburger
              className="lg:hidden text-3xl block ml-auto cursor-pointer"
              onClick={() => setShow((state) => !state)}
            />
          ) : (
            <MdExitToApp
              className="lg:hidden text-3xl ml-auto cursor-pointer"
              onClick={() => setShow((state) => !state)}
            />
          )}
          <ul
            className={`lg:flex flex-col trasns lg:flex-row gap-10 font-pro ${
              show ? 'flex' : 'hidden'
            }`}
          >
            <li
              className="nav-links opacity-0 flex gap-3 items-center cursor-pointer hover:text-orange-200 duration-200"
              onClick={() => handleSectionScroll('about')}
            >
              <BsPersonFill className="text-3xl text-orange-400" />
              About Me
            </li>
            <li
              className="nav-links opacity-0 flex gap-3 items-center cursor-pointer hover:text-orange-200 duration-200"
              onClick={() => handleSectionScroll('experience')}
            >
              <MdWorkHistory className="text-3xl text-orange-400" />
              Experience
            </li>
            <li
              className="nav-links opacity-0 flex gap-3 items-center cursor-pointer hover:text-orange-200 duration-200"
              onClick={() => handleSectionScroll('work')}
            >
              <AiOutlineFundProjectionScreen className="text-3xl text-orange-400" />
              Work
            </li>
            <li
              className="nav-links opacity-0 flex gap-3 items-center cursor-pointer hover:text-orange-200 duration-200"
              onClick={() => handleSectionScroll('contact')}
            >
              <MdOutlineEmojiPeople className="text-3xl text-orange-400" />
              Contact
            </li>
            <li className="nav-links opacity-0 flex gap-3 items-center cursor-pointer hover:text-orange-200 duration-200">
              <a
                target="_blank"
                href="https://drive.google.com/file/d/174W6ptmE80opu-8A30Svj-nTkB8nxHIG/view"
                className="border border-orange-400 text-white px-4 py-2 ml-5 rounded-md hover:-translate-x-2 hover:-translate-y-1 duration-300 hover:shadow-btnShadow"
                download="Resume.pdf"
              >
                Resume
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="fixed bottom-0 hidden w-screen lg:flex">
        <div className="mt-auto w-full px-2 justify-between right-0 bottom-0 lg:flex">
          <aside className="flex items-center gap-6 rotate-90">
            <a href="https://github.com/henok-tesfu">
              <AiFillGithub className="-rotate-90 text-2xl duration-100 cursor-pointer hover:text-orange-500 hover:scale-125" />
            </a>
            <a href="https://www.linkedin.com/in/henok-tesfu-code47/">
              <AiFillLinkedin className="-rotate-90 text-2xl hover:text-orange-500 duration-100 hover:scale-125" />
            </a>
            <hr className="border-none h-[1px] w-[100px] bg-gray-200" />
          </aside>
          <aside className="flex items-center gap-6 mb-[100px] rotate-90 relative translate-x-20">
            <a href="mailTo:henoktesfu48@gmail.com" className="text-gray-400">
              henoktesfu48@gmail.com
            </a>
            <hr className="border-none h-[1px] w-[100px] bg-gray-200" />
          </aside>
        </div>
      </div>
      <section className="flex min-h-screen" id="home">
        <div className="h-full m-auto flex flex-col gap-2">
          <p className="intro opacity-0 text-orange-400 lg:text-3xl">
            Hi, my name is
          </p>
          <h1 className="intro opacity-0 text-5xl lg:text-8xl font-semibold">
            Henok Tesfu
          </h1>
          <TypingText
            setText={setText}
            count={texts.length}
            text={texts[text]}
          />
          <p className="intro opacity-0 mt-3 text-gray-400 lg:text-xl lg:w-1/2">
            I’m a software engineer specializing in building softwares.
            Currently, I’m focused on building web apps, human-centered
            products.
          </p>
        </div>
      </section>
      <section className="my-40 w-full flex flex-col gap-6" id="about">
        <div className="flex w-full items-center gap-2">
          <BsPersonFill className="text-3xl text-orange-400" />
          <h3 className="text-3xl">About me</h3>
          <hr className="bg-gray-500 flex-1 h-[1px] border-none" />
        </div>
        <div className="mt-10 flex flex-col lg:flex-row">
          <div className="flex flex-col gap-4 lg:w-1/2">
            <p className="lg:text-lg">
              Hello! My name is{' '}
              <span className="font-bold text-orange-400">Henok</span> and I
              enjoy creating things that live on the internet. My journey in web
              development began during my elementary school days when I was
              introduced to Mavis Beacon. This software product sparked my
              interest in the world of tech and software. As I progressed
              through high school, computer lab class taught me a lot about HTML
              & CSS! Fast-forward to today, I have had the honor of working for
              several organizations, including tech companies, banks, startups,
              and voluntary organizations. I have also had the opportunity to
              work as a freelancer on various platforms.My current focus is on
              building scalable, maintainable, secure, and efficient web
              products. I enjoy consulting with a variety of clients to help
              bring their ideas to life.
            </p>
            <p>
              Here are some of the{' '}
              <span className="text-orange-400">technologies</span> I’ve been
              working with recently:
            </p>
            <ul className="flex mx-auto lg:m-0 gap-16 max-w-[400px]">
              <div className="flex-1">
                <li className="flex gap-1 items-center py-2">
                  <FaHandPointRight className="min-w-[16px] text-orange-400" />
                  JavaScript
                </li>
                <li className="flex gap-1 items-center py-2">
                  <FaHandPointRight className="min-w-[16px] text-orange-400" />
                  Vue.js
                </li>
                <li className="flex gap-1 items-center py-2">
                  <FaHandPointRight className="min-w-[16px] text-orange-400" />
                  PHP
                </li>
              </div>
              <div className="flex-1">
                <li className="flex gap-1 items-center py-2">
                  <FaHandPointRight className="min-w-[16px] text-orange-400" />
                  Laravel
                </li>
                <li className="flex gap-1 items-center py-2">
                  <FaHandPointRight className="min-w-[16px] text-orange-400" />
                  Docker{' '}
                </li>
                <li className="flex gap-1 items-center py-2">
                  <FaHandPointRight className="min-w-[16px] text-orange-400" />
                  AWS
                </li>
              </div>
            </ul>
          </div>
          <div className="relative mx-auto mt-10 w-[300px] h-[300px] flex justify-center">
            <div className="w-full h-full border absolute left-3 top-3 border-orange-500" />
            <div className="relative z-10 overflow-hidden duration-200 hover:-translate-x-2 hover:-translate-y-1 bg-orange-300">
              <div className="w-full h-full absolute top-0 z-20 duration-200 bg-orange-500/50 hover:bg-transparent" />
              <img
                className="object-cover relative mt-auto ml-4"
                src={Henok}
                alt="profile-pic"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="my-40 w-full" id="experience">
        <div className="flex w-full gap-2 items-center">
          <MdWorkHistory className="text-3xl text-orange-400" />
          <h3 className="text-3xl">Where I've worked</h3>
          <hr className="bg-gray-500 flex-1 h-[1px] border-none" />
        </div>
        <div className="mt-10 flex flex-col gap-4 lg:w-3/5 mx-auto">
          {Companies.map((company) => (
            <div
              className="relative flex flex-col cursor-pointer"
              key={company.name}
              onClick={() => handleSlideClick(company.name.split(' ')[0])}
            >
              <div className="relative z-50 flex items-center justify-between">
                <h3 className="text-2xl text-orange-400 font-bold duration-200 hover:text-orange-100">
                  {company.name}
                </h3>{' '}
                <BsChevronDown
                  className={`text-lg ${
                    activeSlide === `${company.name.split(' ')[0]}`
                      ? 'rotate-180 duration-400 text-orange-400'
                      : ''
                  }`}
                />
              </div>
              <h4
                className={`text-lg text-gray-500 duration-300 flex justify-between ${
                  activeSlide === company.name.split(' ')[0] ? 'text-white' : ''
                }`}
                style={{ fontFamily: 'plexMono' }}
              >
                {company.role} <span>{company.date}</span>
              </h4>
              <ul
                ref={projDescHolder}
                className={`${
                  company.name.split(' ')[0]
                } companies relative flex flex-col gap-2 border-l-4 border-orange-400 h-0 w-5/6 mx-auto overflow-hidden`}
              >
                {company.description.map((task, index) => (
                  <li
                    key={index}
                    className={`${
                      company.name.split(' ')[0]
                    }-role role-list flex gap-2 -translate-x-8 opacity-0`}
                  >
                    <FaHandPointRight className="min-w-[16px] mx-1 text-orange-400" />
                    <p>{task}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
      <section
        className="min-h-screen mt-40 w-full flex flex-col gap-5 lg:gap-20"
        id="work"
      >
        <div className="flex w-full gap-2 items-center">
          <AiOutlineFundProjectionScreen className="text-3xl text-orange-400" />
          <h3 className="text-3xl">Featured Projects</h3>
          <hr className="bg-gray-500 flex-1 h-[1px] border-none" />
        </div>
        <div className="flex flex-col w-full gap-20 lg:gap-40">
          {featuredProjects.map((project, index) => (
            <Project
              key={index}
              project={project}
              type={'Featured Projects'}
              index={index}
              pics={pics[index]}
            />
          ))}
        </div>
        <div className=" mt-20 flex flex-col gap-4">
          <h3 className="text-3xl text-center">Other Projects</h3>
          <p className="font-pro text-xl text-center text-orange-200">
            View the archive
          </p>
          <div className="mt-5 flex flex-col lg:flex-row gap-4">
            {otherProjects.map((proj, index) => (
              <Project
                key={index}
                project={proj}
                type=""
                index={null}
                pics={[]}
              />
            ))}
          </div>
        </div>
      </section>
      <section className=" mt-20 w-full flex flex-col gap-5" id="contact">
        <div className="flex w-full gap-2 items-center">
          <MdOutlineEmojiPeople className="text-3xl text-orange-400" />
          <h3 className="text-3xl">Let's connect</h3>
          <hr className="bg-gray-500 flex-1 h-[1px] border-none" />
        </div>

        <div className="flex flex-col gap-5 my-20">
          <h2 className="text-center text-3xl text-orange-200">
            Get in touch!
          </h2>
          <p className="text-gray-300 text-center">
            I'm always interested in hearing about new projects, so if you'd
            like to chat please get in touch.
          </p>
          <p
            className="text-center text-gray-500 text-2xl lg:text-3xl"
            style={{ fontFamily: 'Caveat, cursive' }}
          >
            "The best dreams happen when we are awake!"
          </p>
          <a href="mailto:henoktesfu48@gmail.com">
            <button
              type="button"
              className="relative mt-5 px-4 py-2 border border-orange-400 block mx-auto text-orange-200 hover:-translate-x-2 hover:-translate-y-1 duration-300 hover:shadow-btnShadow w-max"
            >
              <p>Say Hello</p>
            </button>
          </a>
          <div className="flex gap-10 text-3xl my-10 mx-auto">
            <a href="https://github.com/henok-tesfu">
              <AiFillGithub className="text-2xl duration-100 cursor-pointer hover:text-orange-500 hover:scale-125" />
            </a>
            <a href="https://www.linkedin.com/in/henok-tesfu-code47/">
              <AiFillLinkedin className="text-2xl hover:text-orange-500 duration-100 hover:scale-125" />
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default App;
